import store from '@/state/store'
import { readFromLocalStorage } from '../state/helpers';

//rediriger l'utilisateur vers le login s'il n'est pas authentifié

const requireAuth = (to, from, next) => {
    const token = readFromLocalStorage('token');
    if (token) {
      next(); 
  } else {
    next({ name: 'login' }); 
  }
};


export default [
    {
    path: '/',
    meta: {
        authRequired: true
        },
    name: 'home',
    component: () => import('./views/home'),
    beforeEnter: requireAuth,
},
{
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
        title: "Login",
    },
   
},
{
    path: '/register',
    name: 'register',
    component: () => import('./views/account/register'),
    meta: {
        title: "Register",
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home parametre instead
                next({
                    name: 'home'
                })
            } else {
                // Continue to the login parametre
                next()
            }
        },
    },
},
{
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/account/forgot-password'),
    meta: {
        title: "Forgot password",
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home parametre instead
                next({
                    name: 'home'
                })
            } else {
                // Continue to the login parametre
                next()
            }
        },
    },
},
{
    path: '/logout',
    name: 'logout',
    meta: {
        title: "Logout",
        authRequired: true,
    },
},
{
    path: '/transactions',
    name: 'Transaction',
    component: () => import('../components/Transaction.vue'),
    meta: {
        title: "Transaction",
    },
    beforeEnter: requireAuth,
},
    {
    path: '/devise',
    name: 'devise',
    component: () => import('../components/parametre/Devise.vue'),
    meta: {
        title: "Devise",
        },
    beforeEnter: requireAuth,
},
{
    path: '/operateur',
    name: 'Opérateur',
    meta: { title: "Opérateur", authRequired: true },
    component: () => import('../components/parametre/Opérateur.vue'),
    beforeEnter: requireAuth,
},
{
    path: '/means_of_paiement',
    name: 'Moyen de paiement',
    meta: { title: "Moyen de paiement", authRequired: true },
    component: () => import('../components/parametre/MeansPaiement.vue'),
    beforeEnter: requireAuth,
},
    {
    path: '/utilsateurs',
    name: 'Utilisateurs',
    meta: { title: "Utilisateurs", authRequired: true },
        component: () => import('../components/parametre/User.vue'),
    beforeEnter: requireAuth,
},
    {
    path: '/country',
    name: 'Pays',
    meta: { title: "Pays", authRequired: true },
        component: () => import('../components/parametre/Pays.vue'),
    beforeEnter: requireAuth,
},

{
    path: '/devise/add',
    name: 'add-devise',
    meta: { title: "Devise", authRequired: true },
    component: () => import('../components/devise/AddDevise.vue'),
},
{
    path: '/deviseupdate/:id',
    name: 'update-devise',
    meta: { title: "Devise", authRequired: true },
    component: () => import('../components/devise/UpdateDevise.vue'),
},
{
    path: '/country/add',
    name: 'add-pays',
    meta: { title: "Pays", authRequired: true },
    component: () => import('../components/pays/AddPays.vue'),
},
{
    path: '/means_of_paiement/add',
    name: 'add-means_of_paiement',
    meta: { title: "Moyen_Paiement", authRequired: true },
    component: () => import('../components/meansofPaiement/AddMeans.vue'),
},
{
    path: '/profile',
    name: 'profile',
    meta: { title: "Profile", authRequired: true },
    component: () => import('../components/Profile.vue'),
},
]