<script>
import appConfig from "@/app.config";
import { layoutComputed } from '@/state/helpers.js'
import $ from 'jquery';
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    } 
  },
  computed: {
    ...layoutComputed
  },
  mounted() {
    this.initSiteLayout()
  },
  methods: {
    initSiteLayout() {
      document.body.setAttribute('data-layout', this.layoutType)
      // document.body.setAttribute('data-sidebar', this.leftSidebarType)
      document.body.setAttribute('data-sidebar-size', this.layoutWidth)
      document.body.setAttribute('data-bs-theme', this.mode)
      if (this.dir === 'rtl') {
        document.body.setAttribute('dir', this.dir)        
      }
    }
  }
};
</script>

<style>
.multiselect__tags-wrap .multiselect__tag {
  background-color: #cc3446 !important;
}

.multiselect__option--highlight {
  background: #cc3446 !important;
  outline: none;
  color: white;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #cc3446 !important;
  color: #fff;
}

</style>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
