import { createApp } from 'vue';
import App from './App.vue';
import { vMaska } from "maska"
import * as VueGoogleMaps from 'vue3-google-map';
import VueSweetalert2 from 'vue-sweetalert2';
import Vue3ApexCharts from 'vue3-apexcharts';
import router from './router/index';
import store from '@/state/store';
import vco from "v-click-outside";
import BootstrapVueNext from 'bootstrap-vue-next';
import vueChartist from 'vue-chartist'
import DropZone from 'dropzone-vue';

import MutliCss from '../node_modules/vue-multiselect/dist/vue-multiselect.css';

import 'dropzone-vue/dist/dropzone-vue.common.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@/design/app.scss';
import "@vueform/multiselect/themes/default.css"
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


createApp(App)
.use(MutliCss)
.use(DropZone)
.use(router)
.use(store)
.use(BootstrapVueNext)
.directive("maska", vMaska)
.use(VueSweetalert2)
.use(vueChartist)
  .use(vco)
  .component("v-select",vSelect)
.use(Vue3ApexCharts)
.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
.mount('#app');

  

