import api from './api.js';
export const register = (value) => api.post('/register', value)



export async function loginUser(userData) {
  try {
        const result = await api.post('/login_admin', userData);
      localStorage.setItem('token', result.data.data.token);
       console.log('token is', result.data.data.token);
      window.location.href = '/';
    return null;
  } catch (error) {
    
    console.log(error);

      let errorMessage = "";
        if (error.response && error.response.status === 500) {
            errorMessage = "Une erreur s'est produite, veuillez réessayer";
        } else {
            errorMessage = error.response;
      }
      
      return errorMessage
    }
}


export async function logoutUser() {
  try {
        await api.post('/logout_admin', );
      localStorage.removeItem('token');
      window.location.href = '/login';
    return null;
  } catch (error) {  
      return "Une erreur s'est produite, veuillez réessayer"
    }
}


